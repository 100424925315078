import { useAuth0 } from "@auth0/auth0-react";
import { faCaretRight, faEdit, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import { ActionBar, Headline } from "../components";
import { ContextStore } from "../contexts";
import { GameDto, HeadlineSize } from "../models";
import { useDocTitle } from "../oc6";

export function GamePage(): JSX.Element {
    useDocTitle("Spil");

    const { games } = React.useContext(ContextStore);
    const [expandedGameId, setExpandedGameId] = React.useState<number>(0); //max GameId starts expanded

    React.useEffect(() => {
        if (games.length > 0) {
            setExpandedGameId(games.map(x => x.id).reduce((p, c) => p > c ? p : c));
        }
    }, [games]);

    return (
        <ActionBar add="/game/new">
            {games.length == 0 ?
                <p>Ingen spil</p>
                : games
                    .sort((a, b) => b.id - a.id)
                    .map(game => <GameView
                        key={game.id}
                        game={game}
                        expandedGameId={expandedGameId}
                        setExpandedGameId={v => setExpandedGameId(v)}
                    />)}
        </ActionBar>
    );
}

interface IGameViewProps {
    game: GameDto;
    expandedGameId: number;
    setExpandedGameId: (v: number) => void;
}

function GameView({ game, expandedGameId, setExpandedGameId }: IGameViewProps) {
    const { teams, players } = React.useContext(ContextStore);

    const { isAuthenticated } = useAuth0();

    const expanded = expandedGameId === game.id;

    function renderGame(): JSX.Element {
        return (
            <>
                <div className="flex flex-row flex-wrap">
                    {
                        game.teamIds
                            .map((teamId) => {
                                const team = teams.find(team => team.id === teamId);

                                if (!team) {
                                    return <></>;
                                }

                                return (
                                    <div key={team.id} className="p-3 justify-center items-start flex w-1/2">
                                        <div>
                                            <Headline size={HeadlineSize.H3}>Hold {team.number}</Headline>
                                            <div className="flex flex-col">
                                                {
                                                    team.playerIds
                                                        .map(playerId => {
                                                            const player = players.find(x => x.id === playerId);

                                                            if (!player) {
                                                                return <></>;
                                                            }

                                                            return (
                                                                <div
                                                                    className="pb-1 flex"
                                                                    key={player.id}
                                                                >
                                                                    <div className="w-1/6">
                                                                        <FontAwesomeIcon
                                                                            className={`${game.isCompleted ? "text-gray-300" : "text-lime-400"}`}
                                                                            icon={faCaretRight}
                                                                        />
                                                                    </div>
                                                                    <div className="w-5/6">
                                                                        {player.name}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    </div>);
                            })
                    }
                </div>
            </>
        );
    }

    return (
        <div
            className="mb-5"
            onClick={() => setExpandedGameId(game.id)}
        >
            <div className={`flex flex-row ${game.isCompleted ? "bg-gray-300" : "bg-lime-400"} p-2 items-center shadow-md`}>
                <div className={`${isAuthenticated ? "w-4/6" : "w-5/6"} flex justify-start `}>
                    <Headline size={HeadlineSize.H2}>{game.name}</Headline>
                </div>
                {isAuthenticated ? (
                    <div className="w-1/6 flex justify-center">
                        <NavLink to={`/game/${game.id}`}>
                            <FontAwesomeIcon
                                className="text-2xl"
                                icon={faEdit}
                            />
                        </NavLink>
                    </div>
                ) : <></>}
                <div className="w-1/6 flex justify-end">
                    <FontAwesomeIcon
                        className="text-2xl"
                        icon={expanded ? faMinus : faPlus}
                    />
                </div>
            </div>
            {expanded ? renderGame() : <></>}
        </div >
    );
}