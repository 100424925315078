import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import {  GamePage, PlayersPage, DebugPage, EditPlayerPage, CreateGamePage, EditGamePage, NoAccessPage, CreatePlayerPage } from "../pages";

export function Router() {
    return (
        <Routes>
            <Route path="/" element={<PlayersPage />} />
            <Route path="/player/new" element={<AdminRoute><CreatePlayerPage /></AdminRoute>} />
            <Route path="/player/:id" element={<AdminRoute><EditPlayerPage /></AdminRoute>} />

            <Route path="/debug" element={<AdminRoute><DebugPage /></AdminRoute>} />

            <Route path="/game" element={<GamePage />} />
            <Route path="/game/new" element={<AdminRoute><CreateGamePage /></AdminRoute>} />
            <Route path="/game/:id" element={<AdminRoute><EditGamePage /></AdminRoute>} />
        </Routes>
    );
}

function AdminRoute({ children }: React.PropsWithChildren) {
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated) {
        return (
            <>
                {children}
            </>
        );
    }
    else {
        return (
            <NoAccessPage />
        );
    }
}