import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/stylesheet.css";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { DefaultToastContainer } from "./components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="oc6.eu.auth0.com"
      clientId="ZlZ17f20UKGSUhQ79gI3iqXLFcPtds5r"
      redirectUri={window.location.origin}
      audience="Oc6 API">
      <DefaultToastContainer />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode >
);