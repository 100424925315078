import { useAuth0 } from "@auth0/auth0-react";
import { faArrowRightFromBracket, faBasketball, faCircleUser, faUsers, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

export function Menu() {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    function renderLoginLogout() {
        if (isAuthenticated) {
            return (
                <MenuLink
                    icon={faArrowRightFromBracket}
                    click={() => window.confirm("Logud?") && logout()}
                />
            );
        }
        else {
            return (
                <MenuLink
                    icon={faCircleUser}
                    click={() => loginWithRedirect()} />
            );
        }
    }

    return (
        <div className="flex flex-row justify-around items-center shadow-lg flex-wrap sticky top-0 left-0 bg-lime-600 z-20">
            <MenuLink
                to="/"
                icon={faUsers}
            />
            <MenuLink
                to="/game"
                icon={faBasketball}
            />
            {renderLoginLogout()}
        </div>
    );
}

interface IMenuLinkProps {
    icon: IconDefinition;
    to?: string;
    click?: () => void;
    inActive?: boolean;
}

function MenuLink({ icon, to, click = () => { } }: IMenuLinkProps) {
    return (
        <div className={"inline-block lg:w-1/5"}>
            <NavLink
                onClick={() => click()}
                className="p-4 text-xl flex flex-row justify-center items-center text-white"
                to={to ?? ""}>
                <FontAwesomeIcon
                    className="text-4xl"
                    icon={icon} />
            </NavLink>
        </div>
    );
}
