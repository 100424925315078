export interface GameDto {
    id: number;
    name: string;
    isCompleted: boolean;
    teamIds: Array<number>;
}

export const getDefaultGameDto: () => GameDto = () => ({
    id: -Math.random(),
    name: "",
    isCompleted: false,
    teamIds: []
});