import { TeamDto } from "../models";
import { AbstractController } from ".";

class DefaultTeamController extends AbstractController {
    public constructor() {
        super("/team");
    }

    public async getAll(): Promise<Array<TeamDto>> {
        return await this.getRequest("/");
    }
}

export const TeamController: DefaultTeamController = new DefaultTeamController();