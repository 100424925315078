import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionBar, Headline, InputBox, SpinnerContainer } from "../components";
import { ContextStore } from "../contexts";
import { FinishGameDto, GameDto, getDefaultGameDto, HeadlineSize, TeamDto } from "../models";
import { NoAccessPage } from "./NoAccessPage";

type FinishGamePageRouteParams = {
    id: string;
}

interface ExtendedTeamDto extends TeamDto {
    pointsString: string;
}

export function EditGamePage() {
    const { id } = useParams<FinishGamePageRouteParams>();

    if (!id) {
        return <NoAccessPage />;
    }

    const idValue = parseInt(id);

    const navigateTo = useNavigate();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { games, finishGame, teams, deleteGame } = React.useContext(ContextStore);
    const [extendedTeams, setExtendedTeams] = React.useState<Array<ExtendedTeamDto>>([]);
    const [game, setGame] = React.useState<GameDto>(getDefaultGameDto());

    React.useEffect(() => {
        const newGame = games.find(x => x.id === idValue);

        if (newGame) {
            setGame(newGame);
        }
    }, [games]);

    React.useEffect(() => {
        const newExtendedTeams = teams.filter(x => game.teamIds.indexOf(x.id) >= 0).map(team => ({ ...team, pointsString: team.points.toString() }));

        if (newExtendedTeams) {
            setExtendedTeams(newExtendedTeams);
        }
    }, [teams, game]);

    if (isLoading) {
        return <SpinnerContainer />;
    }


    if (extendedTeams.length === 0) {
        return <SpinnerContainer />;
    }

    function setPoints(pointsString: string, id: number) {

        const extendedTeam = extendedTeams.find(x => x.id === id);

        if (extendedTeam) {
            setExtendedTeams([{ ...extendedTeam, pointsString }, ...extendedTeams.filter(x => x.id !== id)]);
        }
    }

    async function save(game: GameDto): Promise<void> {
        const dto: FinishGameDto = {
            id: game.id,
            teamScores: [
                ...extendedTeams.map(team => {
                    let points = parseInt(team.pointsString);

                    if (!points) {
                        points = 0;
                    }

                    return {
                        id: team.id,
                        points
                    };
                })
            ]
        };

        setIsLoading(true);

        if (await finishGame(dto)) {
            navigateTo("/");
        }

        setIsLoading(false);
    }

    async function deleteFunction(): Promise<void> {
        if (game) {
            if (window.confirm("Slet spil?")) {
                setIsLoading(true);

                if (await deleteGame(game)) {
                    navigateTo("/");
                }

                setIsLoading(false);
            }
        }
    }

    return (
        <ActionBar
            deleteFunction={() => deleteFunction()}
            saveFunction={() => save(game)}
        >
            <div className="flex flex-col">
                <div className={`${game.isCompleted ? "bg-gray-300" : "bg-lime-400"} p-3 mb-3 shadow-md text-center`}>
                    <Headline size={HeadlineSize.H2}>{game.name}</Headline>
                </div>

                {game.teamIds.map(teamId => {
                    const extendedTeam = extendedTeams.find(x => x.id === teamId);

                    if (!extendedTeam) {
                        return <></>;
                    }

                    return (
                        <div key={teamId}>
                            <div>
                                <InputBox
                                    onChange={e => setPoints(e.target.value, extendedTeam.id)}
                                    value={extendedTeam.pointsString}
                                    type="number"
                                    label={`Team ${extendedTeam.number}`} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </ActionBar>
    );
}