import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionBar, Headline, InputBox, SpinnerContainer } from "../components";
import { ContextStore } from "../contexts";
import { HeadlineSize } from "../models";

export function CreateGamePage() {
    const { players, createGame } = React.useContext(ContextStore);
    const [checkedPlayers, setCheckedPlayers] = React.useState<Array<CheckedPlayer>>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        setCheckedPlayers(players.map(player => ({
            id: player.id,
            name: player.name,
            isChecked: true
        })));
    }, [players]);

    const [name, setName] = React.useState<string>("");
    const [teamCount, setTeamCount] = React.useState<number>(2);

    async function save() {
        setIsLoading(true);

        if (await createGame({
            teamCount,
            name,
            playerIds: checkedPlayers
                .filter(x => x.isChecked)
                .map(x => x.id)
        })) {
            navigate("/game");
        }

        setIsLoading(false);
    }

    function handleCheck(id: number) {
        setCheckedPlayers(checkedPlayers.map(player => player.id === id ? { ...player, isChecked: !player.isChecked } : player));
    }

    if (isLoading) {
        return <SpinnerContainer />;
    }

    return (
        <ActionBar saveFunction={() => save()} >
            <div className="flex flex-col">
                <div className="bg-lime-400 p-3 mb-3 shadow-md text-center">
                    <Headline size={HeadlineSize.H2}>Opret nyt spil</Headline>
                </div>
                <div className="flex flex-col">
                    <InputBox
                        onChange={e => setName(e.target.value)}
                        value={name}
                        label="Navn" />
                    <InputBox
                        onChange={e => setTeamCount(parseInt(e.target.value))}
                        value={teamCount}
                        type="number"
                        label="Antal hold" />
                    <div className="flex flex-col">
                        <div className="bg-lime-400 p-3 mb-3 shadow-md text-center">
                            <Headline size={HeadlineSize.H3}>Vælg deltagere</Headline>
                        </div>
                        <div className="flex flex-row flex-wrap">
                            {checkedPlayers.map(({ id, name, isChecked }) => (
                                <label key={id} className="mb-5 sm:w-1/3 lg:w-1/5 m-2 border border-lime-400 p-3 shadow-md">
                                    <input
                                        checked={isChecked}
                                        onChange={() => handleCheck(id)}
                                        className=" bg-lime-100 text-lime-400 h-8 w-8 mr-4 rounded focus:ring-lime-600 focus:ring-2"
                                        type="checkbox" />
                                    {name}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ActionBar>
    );
}

interface CheckedPlayer {
    id: number;
    name: string;
    isChecked: boolean;
}