import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionBar, Headline, InputBox, SpinnerContainer } from "../components";
import { ContextStore } from "../contexts";
import { getDefaultPlayerDto, HeadlineSize, PlayerDto } from "../models";
import { NoAccessPage } from "./NoAccessPage";

type EditPlayerPageRouteParams = {
    id: string;
}

export function EditPlayerPage() {
    const { id } = useParams<EditPlayerPageRouteParams>();

    const navigate = useNavigate();

    if (!id) {
        return <NoAccessPage />;
    }

    const idValue = parseInt(id);

    const { players, updatePlayer, deletePlayer } = React.useContext(ContextStore);

    const [player, setPlayer] = React.useState<PlayerDto>(getDefaultPlayerDto());
    const [name, setName] = React.useState<string>("");
    const [basePoints, setBasePoints] = React.useState<string>("0");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const newPlayer = players.find(x => x.id === idValue);

        if (newPlayer) {
            setPlayer(newPlayer);
        }
    }, [players]);

    React.useEffect(() => {
        setName(player.name);
        setBasePoints(player.basePoints.toString());
    }, [player]);

    async function save(): Promise<void> {
        if (name) {
            setIsLoading(true);

            if (await updatePlayer({
                ...player,
                name: name,
                basePoints: parseInt(basePoints)
            })) {
                navigate("/");
            }

            setIsLoading(false);
        }
    }

    async function deleteFunction(): Promise<void> {
        setIsLoading(true);

        if (window.confirm("Slet spiller?")) {
            if (await deletePlayer(player)) {
                navigate("/");
            }
        }

        setIsLoading(false);
    }

    if (isLoading) {
        return <SpinnerContainer />;
    }

    return (
        <ActionBar
            saveFunction={() => save()}
            deleteFunction={() => deleteFunction()}
        >
            <div className="flex flex-col">
                <div className="bg-lime-400 p-3 mb-3 shadow-md text-center">
                    <Headline size={HeadlineSize.H1}>{player.name}</Headline>
                </div>
                <div className="flex flex-col">
                    <InputBox
                        onChange={e => setName(e.target.value)}
                        value={name}
                        label="Navn" />
                    <InputBox
                        onChange={e => setBasePoints(e.target.value)}
                        value={basePoints}
                        label="Strafpoints" />
                </div>
            </div>
        </ActionBar>
    );
}