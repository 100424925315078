import { useAuth0 } from "@auth0/auth0-react";
import { faDeleteLeft, faPlusCircle, faRecycle, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import { ContextStore } from "../contexts";

type SaveFunction = () => void;
type DeleteFunction = SaveFunction;

interface IActionBarProps extends React.PropsWithChildren {
    add?: string;
    saveFunction?: SaveFunction;
    deleteFunction?: DeleteFunction;
}

export function ActionBar({ saveFunction, deleteFunction, add, children }: IActionBarProps) {
    const { reload } = React.useContext(ContextStore);

    const { isAuthenticated } = useAuth0();

    function renderSave() {
        if (isAuthenticated && saveFunction) {
            return (
                <div>
                    <button
                        onClick={() => saveFunction()}
                        type="button"
                        className="w-16 h-16 text-lime-500">
                        <FontAwesomeIcon
                            className="text-4xl"
                            icon={faSave}
                        />
                    </button>
                </div>
            );
        }
        else {
            return <></>;
        }
    }

    function renderAdd() {
        if (isAuthenticated && add) {
            return (
                <div>
                    <NavLink
                        to={add}
                        className="w-16 h-16 text-lime-500">
                        <FontAwesomeIcon
                            className="text-4xl"
                            icon={faPlusCircle}
                        />
                    </NavLink>
                </div>
            );
        }
        else {
            return <></>;
        }
    }

    function renderDelete() {
        if (isAuthenticated && deleteFunction) {
            return (
                <div>
                    <button
                        onClick={() => deleteFunction()}
                        type="button"
                        className="w-16 h-16 text-orange-500">
                        <FontAwesomeIcon
                            className="text-4xl"
                            icon={faDeleteLeft}
                        />
                    </button>
                </div>
            );
        }
        else {
            return <></>;
        }
    }

    return (
        <>
            {children}
            <div className="fixed h-24 bottom-0 left-0 w-full bg-gray-200 p-4 z-30">
                <div className="flex flex-row justify-around items-center">
                    <div>
                        <button
                            onClick={() => reload()}
                            type="button"
                            className="w-16 h-16 text-lime-500">
                            <FontAwesomeIcon
                                className="text-4xl"
                                icon={faRecycle}
                            />
                        </button>
                    </div>
                    {renderDelete()}
                    {renderSave()}
                    {renderAdd()}
                </div>
            </div>
        </>
    );
}