import { PlayerDto } from "../models";
import { AbstractController } from "./";

class DefaultPlayerController extends AbstractController {
    public constructor() {
        super("/player");
    }

    public async getById(id: number): Promise<PlayerDto> {
        return await this.getRequest("/" + id);
    }

    public async getAll(): Promise<Array<PlayerDto>> {
        return await this.getRequest("/");
    }

    public async post(player: PlayerDto, token: string): Promise<PlayerDto> {
        return await this.postRequest("/", token, player);
    }

    public async put(player: PlayerDto, token: string): Promise<PlayerDto> {
        return await this.putRequest("/", token, player);
    }

    public async delete(id: number, token: string): Promise<void> {
        await this.deleteRequest("/" + id, token);
    }
}

export const PlayerController: DefaultPlayerController = new DefaultPlayerController();