import { useAuth0 } from "@auth0/auth0-react";
import { faBan, faChartLine, faEdit, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import { ActionBar } from "../components";
import { ContextStore } from "../contexts";
import { useDocTitle } from "../oc6";

export function PlayersPage() {
    useDocTitle("Spillere");

    const { isAuthenticated } = useAuth0();

    const { players } = React.useContext(ContextStore);

    return (
        <ActionBar add="/player/new">
            <div className="sticky top-20">
                <div className="bg-lime-400 py-5 px-2 flex flex-row shadow-md">
                    <div className={`${isAuthenticated ? "w-7/12" : "w-8/12"} font-bold`}>
                        Spiller
                    </div>
                    <div className="w-1/12 items-center flex text-xs text-gray-400">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className="w-1/12 items-center flex text-xs text-gray-400">
                        <FontAwesomeIcon icon={faUsers} />
                    </div>
                    <div className="w-1/12 items-center flex text-xs text-gray-400">
                        <FontAwesomeIcon icon={faBan} />
                    </div>
                    <div className="w-1/12 items-center flex">
                        <FontAwesomeIcon icon={faChartLine} />
                    </div>
                    {isAuthenticated && <div className="w-1/12" />}
                </div>
            </div>
            {players
                .sort((a, b) => a.basePoints - b.basePoints) //lowest basepoint first
                .sort((a, b) => a.nonGamePoints - b.nonGamePoints) //lowest non-game score first
                .sort((a, b) => a.totalPoints - b.totalPoints) //lowest first
                .map(player => (
                    <div key={player.id} className="odd:bg-lime-200 py-5 px-2 flex flex-row">
                        <div className={`${isAuthenticated ? "w-7/12" : "w-8/12"} font-bold`}>
                            <div>{player.name}</div>
                        </div>
                        <div className="w-1/12 text-right items-center flex text-xs text-gray-400">
                            <div>{player.basePoints.toLocaleString()}</div>
                        </div>
                        <div className="w-1/12 text-right items-center flex text-xs text-gray-400">
                            <div>{player.teamPoints.toLocaleString()}</div>
                        </div>
                        <div className="w-1/12 text-right items-center flex text-xs text-gray-400">
                            <div>{player.nonGamePoints.toLocaleString()}</div>
                        </div>
                        <div className="w-1/12 text-right items-center flex font-bold text-lg">
                            <div>{player.totalPoints.toLocaleString()}</div>
                        </div>
                        {isAuthenticated && (
                            <NavLink
                                to={`player/${player.id}`}
                                className="w-1/12 text-right"
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                />
                            </NavLink>
                        )}
                    </div>
                ))
            }
        </ActionBar >
    );
}