import React from "react";
import { DefaultLayout } from "./components";
import { Router } from "./routing";
import { ContextStoreProvider } from "./contexts";

function App() {
    return (
        <>
            <ContextStoreProvider >
                <DefaultLayout>
                    <Router />
                </DefaultLayout>
            </ContextStoreProvider>
        </>
    );
}

export { App };
