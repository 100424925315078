import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionBar, Headline, InputBox, SpinnerContainer } from "../components";
import { ContextStore } from "../contexts";
import { getDefaultPlayerDto, HeadlineSize } from "../models";

export function CreatePlayerPage() {
    const [name, setName] = React.useState<string>("");
    const [basePoints, setBasePoints] = React.useState<string>("0");
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const { createPlayer } = React.useContext(ContextStore);

    const navigate = useNavigate();

    async function save(): Promise<void> {
        if (name) {
            setIsLoading(true);

            if (await createPlayer({
                ...getDefaultPlayerDto(),
                name: name,
                basePoints: parseInt(basePoints)
            })) {
                navigate("/");
            }

            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <SpinnerContainer />;
    }

    return (
        <ActionBar saveFunction={() => save()} >
            <div className="flex flex-col">
                <div className="bg-lime-400 p-3 mb-3 shadow-md text-center">
                    <Headline size={HeadlineSize.H2}>Opret spiller</Headline>
                </div>
                <div className="flex flex-col">
                    <InputBox
                        onChange={e => setName(e.target.value)}
                        value={name}
                        label="Navn" />
                    <InputBox
                        onChange={e => setBasePoints(e.target.value)}
                        value={basePoints}
                        label="Strafpoints" />
                </div>
            </div>
        </ActionBar>
    );
}