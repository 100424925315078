import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { toast } from "react-toastify";
import { ErrorLoadingView, SpinnerContainer } from "../components";
import { GameController, PlayerController, TeamController } from "../controllers";
import { GameDto, LoadableObject, LoadState, GameCreateRequest, FinishGameDto, TeamDto, PlayerDto } from "../models";

const notImplementedFunction = async () => false;

interface IContextStoreProps {
    games: Array<GameDto>;
    createGame: (request: GameCreateRequest) => Promise<boolean>;
    finishGame: (dto: FinishGameDto) => Promise<boolean>;
    deleteGame: (game: GameDto) => Promise<boolean>;

    teams: Array<TeamDto>;

    players: Array<PlayerDto>;
    createPlayer: (player: PlayerDto) => Promise<boolean>;
    updatePlayer: (player: PlayerDto) => Promise<boolean>;
    deletePlayer: (player: PlayerDto) => Promise<boolean>;

    reload: () => void;
}

export const ContextStore = React.createContext<IContextStoreProps>({
    games: [],
    createGame: notImplementedFunction,
    finishGame: notImplementedFunction,
    deleteGame: notImplementedFunction,

    teams: [],

    players: [],
    createPlayer: notImplementedFunction,
    updatePlayer: notImplementedFunction,
    deletePlayer: notImplementedFunction,

    reload: () => { }
});

interface IContextStoreProviderProps extends React.PropsWithChildren {

}

export function ContextStoreProvider(props: IContextStoreProviderProps) {
    const { getAccessTokenSilently } = useAuth0();

    const [games, setGames] = React.useState<LoadableObject<Array<GameDto>>>({
        value: [],
        state: LoadState.None
    });

    const [teams, setTeams] = React.useState<LoadableObject<Array<TeamDto>>>({
        value: [],
        state: LoadState.None
    });

    const [players, setPlayers] = React.useState<LoadableObject<Array<PlayerDto>>>({
        value: [],
        state: LoadState.None
    });

    React.useEffect(() => {
        if (games.state === LoadState.None) {
            loadGames();

            setGames({ ...games, state: LoadState.Loading });
        }
    }, [games]);

    React.useEffect(() => {
        if (players.state === LoadState.None) {
            loadPlayers();

            setPlayers({ ...players, state: LoadState.Loading });
        }
    }, [players]);

    React.useEffect(() => {
        if (teams.state === LoadState.None) {
            loadTeams();

            setTeams({ ...teams, state: LoadState.Loading });
        }
    }, [teams]);

    async function loadGames(): Promise<void> {
        try {
            const value = await GameController.getAll();

            setGames({
                ...games,
                value,
                state: LoadState.Success
            });
        }
        catch (error) {
            setGames({
                ...games,
                state: LoadState.Error
            });

            toast.error("Kunne ikke loade spillere");
        }
    }

    async function loadPlayers(): Promise<void> {
        try {
            const value = await PlayerController.getAll();

            setPlayers({
                ...players,
                value,
                state: LoadState.Success
            });
        }
        catch (error) {
            setPlayers({
                ...players,
                state: LoadState.Error
            });

            toast.error("Kunne ikke loade spillere");
        }
    }

    async function loadTeams(): Promise<void> {
        try {
            const value = await TeamController.getAll();

            setTeams({
                ...teams,
                value,
                state: LoadState.Success
            });
        }
        catch (error) {
            setTeams({
                ...teams,
                state: LoadState.Error
            });

            toast.error("Kunne ikke loade spillere");
        }
    }

    async function reload(): Promise<void> {
        await loadGames();
        await loadTeams();
        await loadPlayers();
    }

    async function createGame(request: GameCreateRequest): Promise<boolean> {
        try {
            const token = await getAccessTokenSilently();

            await GameController.create(request, token);

            await reload();

            return true;
        }
        catch (error) {
            toast.error("Kunne ikke oprette nyt spil");

            return false;
        }
    }

    async function createPlayer(player: PlayerDto): Promise<boolean> {
        try {
            const token = await getAccessTokenSilently();

            await PlayerController.post(player, token);

            await reload();

            return true;
        }
        catch (error) {
            toast.error("Kunne ikke oprette ny spiller");

            return false;
        }
    }

    async function updatePlayer(player: PlayerDto): Promise<boolean> {
        try {
            const token = await getAccessTokenSilently();

            await PlayerController.put(player, token);

            await reload();

            return true;
        }
        catch (error) {
            toast.error("Kunne ikke opdatere spiller");

            return false;
        }
    }

    async function deletePlayer(player: PlayerDto): Promise<boolean> {
        try {
            const token = await getAccessTokenSilently();

            await PlayerController.delete(player.id, token);

            await reload();

            return true;
        }
        catch (error) {
            toast.error("Kunne ikke slette spiller");

            return false;
        }
    }

    async function finishGame(dto: FinishGameDto): Promise<boolean> {
        try {
            const token = await getAccessTokenSilently();

            await GameController.finish(dto, token);

            await reload();

            return true;
        }
        catch (error) {
            toast.error("Kunne ikke afslutte spil");

            console.log(error);

            return false;
        }
    }

    async function deleteGame(game: GameDto): Promise<boolean> {
        try {
            const token = await getAccessTokenSilently();

            await GameController.delete(game.id, token);

            await reload();

            return true;
        }
        catch (error) {
            toast.error("Kunne ikke slette spiller");

            return false;
        }
    }

    if (players.state === LoadState.Error
        || teams.state === LoadState.Error
        || games.state === LoadState.Error) {
        return <ErrorLoadingView />;
    }

    if (players.state === LoadState.Success
        && teams.state === LoadState.Success
        && games.state === LoadState.Success) {
        return <ContextStore.Provider
            children={props.children}
            value={
                {
                    games: games.value,
                    createGame: async (request: GameCreateRequest) => await createGame(request),
                    finishGame: async (dto: FinishGameDto) => await finishGame(dto),
                    deleteGame: async (game: GameDto) => await deleteGame(game),

                    teams: teams.value,

                    players: players.value,
                    createPlayer: async (player: PlayerDto) => await createPlayer(player),
                    updatePlayer: async (player: PlayerDto) => await updatePlayer(player),
                    deletePlayer: async (player: PlayerDto) => await deletePlayer(player),

                    reload: () => reload()
                }
            } />;
    }

    return <SpinnerContainer />;
}