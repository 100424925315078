import { FinishGameDto, GameCreateRequest, GameDto } from "../models";
import { AbstractController } from ".";

class DefaultGameController extends AbstractController {
    public constructor() {
        super("/game");
    }

    public async getAll(): Promise<Array<GameDto>> {
        return await this.getRequest("/");
    }

    public async create(request: GameCreateRequest, token: string): Promise<GameDto> {
        return await this.postRequest("/", token, request);
    }

    public async finish(dto: FinishGameDto, token: string): Promise<void> {
        await this.putRequest("/finish", token, dto);
    }

    public async delete(id: number, token: string): Promise<void> {
        await this.deleteRequest("/" + id, token);
    }
}

export const GameController: DefaultGameController = new DefaultGameController();