interface IInputBoxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label: string;
}

export function InputBox({ label, ...props }: IInputBoxProps) {
    return (
        <label className="mb-5 flex flex-row w-full">
            <div className="inline-block w-1/3 py-5">{label}:</div>
            <input
                className="w-2/3 inline-block p-5 text-xl border"
                {...props} />
        </label>
    );
}