export interface PlayerDto {
    id: number;
    name: string;
    basePoints: number;
    teamPoints: number;
    nonGamePoints: number;
    totalPoints: number;
}

export const getDefaultPlayerDto: () => PlayerDto = () => ({
    id: 0,
    name: "",
    basePoints: 0,
    teamPoints: 0,
    nonGamePoints: 0,
    totalPoints: 0
});